import {Card, CardContent, CardHeader, Grid, GridProps, TypographyProps} from '@mui/material';
import Link from 'next/link';
import {FC} from 'react';
import {makeStyles} from 'tss-react/mui';

export type HomeCardProps = {
    gridProps?: GridProps;
    color?: string;
    title?: React.ReactNode;
    titleProps?: TypographyProps;
    link: string;
    children?: React.ReactNode;
    permission?: boolean;
};

export const HomeCard: FC<HomeCardProps> = ({gridProps, title, titleProps, children, color, link, permission}) => {
    const {classes} = useStyles({color});

    if (!permission) return null;

    return (
        <Link href={link} legacyBehavior passHref>
            <Grid item={true} xs={6} md={3} {...gridProps}>
                <Card className={classes.root}>
                    {title && <CardHeader title={title} titleTypographyProps={titleProps ? titleProps : {align: 'center', sx: {fontSize: '24px', color}}} />}
                    <CardContent>
                        {children}
                    </CardContent>
                </Card>
            </Grid>
        </Link>
    );
};

const useStyles = makeStyles<{color?: string;}>()((theme, {color}) => ({
    root: {
        height: '100%',
        minHeight: '200px',
        paddingTop: theme.spacing(2),
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-around',
        transition: 'transform 0.15s ease-in-out',
        '&:hover': {
            transform: 'scale3d(1.1, 1.1, 1)',
            cursor: 'pointer',
            border: `1px solid ${color}`,
        },
    },
}));
