import {useUser} from 'contexts/user';
import {Forbidden} from 'core/exceptions';
import {UserFragment} from 'generated/graphql';
import {useRouter} from 'next/router';
import {useEffect} from 'react';
import {LOGIN_URL} from 'settings';

type UserCheck = (user: UserFragment) => boolean;

export const userCheckRequired = (check: UserCheck) => (Component: React.ComponentType) => () => {
    const router = useRouter();
    const {user} = useUser();

    useEffect(() => {
        if (!user) {
            router.push(`${LOGIN_URL}?next=${router.asPath}`);
        } else if (!check(user)) {
            throw new Forbidden();
        }
    }, [user]);

    return user ? <Component /> : null;
};

export const loginRequired = userCheckRequired(user => Boolean(user));
export const isOemRequired = userCheckRequired(user => user && !!user.organization?.group.isOem);
export const isManagerOemRequired = userCheckRequired(user => user && !!user.permissions.manageOem);
export const checkForPermission = (permission: keyof UserFragment['permissions'] | (keyof UserFragment['permissions'])[]) =>
    userCheckRequired(user =>
        Boolean(user) && (
            Array.isArray(permission)
                ? permission.every(p => Boolean(user.permissions[p]))
                : Boolean(user.permissions[permission])
        )
    );
