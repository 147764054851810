import {t, Trans} from '@lingui/macro';
import {Container, Divider, Grid, Typography} from '@mui/material';
import {useUser} from 'contexts/user';
import {loginRequired} from 'core/auth';
import {HomeCard, HomeCardProps} from 'core/components/cards/home-card';
import {NextPage} from 'next';
import React from 'react';

const Home: NextPage = () => {
    const {user: {permissions, organization} = {}} = useUser();

    if (!permissions) return null;

    const cards: HomeCardProps[] = [
        {permission: !organization?.group.isOem && permissions.viewQuotes, title: t`Quoting`, link: '/cotizador/quotes', children: t`Create quotes, generate sales, create accounts.`, color: '#5C5A89'},
        {permission: permissions.viewSupplyOrders, title: t`Supply`, link: '/cotizador/supply-orders', children: t`Follow the status of the supply orders.`, color: '#456C7D'},
        {permission: permissions.viewOrderTracking, title: t`Order tracking`, link: '/cotizador/order-tracking', children: t`Track the shipment status of the supply order.`, color: '#48A281'},
        {permission: permissions.viewMachineStartUps, title: t`PEM`, link: '/cotizador/machine-start-ups', children: t`Do the machine start ups.`, color: '#828482'},
        {permission: permissions.viewOems, title: t`OEM`, link: '/cotizador/oem-orders', children: t`Perform an order order from the app.`, color: '#E3813D'},
        {permission: permissions.viewWarrantyClaims, title: t`Warranty claim`, link: '/cotizador/warranty-claims', children: t`Create warranty claims.`, color: '#9FC569'},
        {permission: permissions.viewAnalytics && !organization?.group.isOem, title: t`Analytics`, link: '/cotizador/analytics', children: t`Statistics of quotes, sales, potential sales, supply orders, etc.`, color: '#393F3B'},
        {permission: permissions.viewEcns, title: 'ECNs', link: '/parts-management', children: 'Notificaciones de cambio de ingeniería', color: '#268EF0'},
        {permission: permissions.viewMaterialMaster, title: 'Material master', link: '/parts-management/material-master', children: 'Herramienta de gestión de piezas', color: '#000000'},
        {permission: permissions.viewEcns, title: 'Estadisticas de ECNs', link: '/parts-management/analytics', children: 'Estadisticas sobre notificaciones de cambio de ingeniería', color: '#268EF0'},
    ];

    return (
        <Container maxWidth='lg'>
            <Grid container={true} spacing={4}>
                <Grid item={true} xs={12}>
                    <Typography sx={{fontSize: '24px'}} color='primary'>
                        <Trans>We welcome you to Precision Planting!</Trans>
                    </Typography>
                </Grid>
                <Grid item={true} xs={12}>
                    <Divider />
                </Grid>
                <Grid item={true} xs={12}>
                    <Typography variant='subtitle1' color='textSecondary'>
                        <Trans>Chose one access to continue.</Trans>
                    </Typography>
                </Grid>
                {cards.map((card, index) => <HomeCard key={index} gridProps={{xs: 12, sm: 4, md: 3}} {...card} />)}
            </Grid>
        </Container>
    );
};

export default loginRequired(Home);
